<template>
  <div
    class="treatment__container"
    :class="{ open: isOpened, 'treatment__container--is-coming-soon': !hasProtectProducts }"
  >
    <treatment-header
      :title="$t('dashboard.label.protect')"
      :icons="protectIconsConfig"
      :is-opened="isOpened"
      :price="protectTreatmentPrice"
      :has-recommendation="hasTreatmentRecommendation && hasProtectProducts"
      :is-coming-soon="!hasProtectProducts"
      @section-toggle="toggleSection"
    />
    <slide-down :show="isOpened">
      <div
        slot="slide-body"
        class="flex--center--center treatment__content"
        :class="{ 'treatment__content--opened': isOpened }"
      >
        <template v-for="product in cleanProducts">
          <complementary-care-product
            :key="product.name"
            :product="product"
            :is-active="getIsProductActive(product.name)"
            @toggle-active="updateProduct"
            @update-product="updateProduct($event, true)"
          />
        </template>
      </div>
    </slide-down>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';
import TreatmentHeader from '@/modules/dashboard/components/create-treatment-tab/treatments/TreatmentHeader';
import SlideDown from '@/modules/dashboard/components/common/SlideDown';
import ComplementaryCareProduct from '@/modules/dashboard/components/create-treatment-tab/treatments/ComplementaryCareProduct';
import SectionIcon from '@/modules/dashboard/components/create-treatment-tab/treatments/SectionIcon';
import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';
import {
  CREATE_TREATMENT_SECTION_NAMES,
  TREATMENT_BOTTLE_ICONS_CONFIG,
  PRODUCT_REFERENCES
} from '@/modules/dashboard/api/constants';
import { COUNTRY_ISO } from '@/api/places/COUNTRIES';


const COUNTRIES_USCA_UNAVAILABLE_UNIVERSKIN_UV = [
  COUNTRY_ISO.CANADA,
  COUNTRY_ISO.UNITED_STATES
];

const COUNTRIES_NZAU_UNAVAILABLE_UNIVERSKIN_UV = [
  COUNTRY_ISO.NEW_ZEELAND,
  COUNTRY_ISO.AUSTRALIA
];


export default {
  name: 'ProtectTreatment',
  components: { TreatmentHeader, SlideDown, ComplementaryCareProduct },
  props: {
    isOpened: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      hasTreatmentRecommendation: dashTypes.getters.HAS_TREATMENT_RECOMMENDATION,
      protectProducts: dashTypes.getters.PROTECT_PRODUCTS,
      protectProductsReferences: dashTypes.getters.PROTECT_PRODUCTS_REFERENCES,
      protectTreatment: dashTypes.getters.PROTECT_TREATMENT,
      protectTreatmentPrice: dashTypes.getters.PROTECT_TREATMENT_PRICE,
      user: rootTypes.getters.GET_USER
    }),
    protectIconsConfig() {
      return this.protectProductsReferences.map(reference => {
        const isProductActive = this.protectTreatment.includes(reference);

        return new SectionIcon(TREATMENT_BOTTLE_ICONS_CONFIG[reference], isProductActive);
      });
    },
    hasProtectProducts() {
      // if (isEmpty(this.protectProducts)) return false;
      const { country } = this.user;
      const filteredShit = [];
      if (COUNTRIES_USCA_UNAVAILABLE_UNIVERSKIN_UV.includes(country)) {
        const filteredShit = this.cleanProducts; // This will trigger the computed property logic to filter the products.
        return filteredShit.length > 0;
      }else if (COUNTRIES_NZAU_UNAVAILABLE_UNIVERSKIN_UV.includes(country)) {
        const filteredShit = this.cleanProducts; // This will trigger the computed property logic to filter the products.
        return filteredShit.length > 0;
      }
      return this.protectProducts.length>0;
    },
    cleanProducts() {
      const { country } = this.user;
      if (COUNTRIES_USCA_UNAVAILABLE_UNIVERSKIN_UV.includes(country)) {
        // Filter out the unwanted products
        return this.protectProducts.filter(product => product.name !== PRODUCT_REFERENCES.NEXULTRA_UV && product.name !== PRODUCT_REFERENCES.SUNSCREEN_SPF && product.name !== PRODUCT_REFERENCES.NEXULTRA_L && product.name !== PRODUCT_REFERENCES.NEXULTRA_L_BRUSH);
      }
      if (COUNTRIES_NZAU_UNAVAILABLE_UNIVERSKIN_UV.includes(country)) {
        // Filter out the unwanted products
        return this.protectProducts.filter(product => product.name !== PRODUCT_REFERENCES.NEXULTRA_UV && product.name !== PRODUCT_REFERENCES.SUNSCREEN_SPF && product.name !== PRODUCT_REFERENCES.NEXULTRA_L);
      }
      return this.protectProducts;
    }
  },
  methods: {
    ...mapActions({
      toggleComplementaryCareProduct: dashTypes.actions.TOGGLE_COMPLEMENTARY_CARE_PRODUCT,
      updateComplementaryCareProduct: dashTypes.actions.UPDATE_COMPLEMENTARY_CARE_PRODUCT
    }),
    toggleSection() {
      if (this.hasProtectProducts) {
        this.$emit('toggle-selection');
      }
    },
    getIsProductActive(product) {
      // alert(JSON.stringify(product, null, 4));
      return this.protectTreatment.includes(product);
    },
    async updateProduct(product, isUpdate = false) {
      const actionParams = {
        ...product,
        section: CREATE_TREATMENT_SECTION_NAMES.PROTECT,
        isUpdate
      };

      if (isUpdate) {
        await this.updateComplementaryCareProduct(actionParams);

        return;
      }

      await this.toggleComplementaryCareProduct(actionParams);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/treatment/create-treatment-shared';

.treatment {
  &__content {
    min-height: 100px;
  }
}

@media (max-width: 767px) {
  .treatment {
    &__content {
      height: auto;
    }
  }
}
</style>
