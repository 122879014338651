<template>
  <div class="create-treatment__container">
    <div class="create-treatment">
      <mobile-header>
        <span slot="header-title" class="header-title">{{
          $t('dashboard.label.createTreatmentTab')
        }}</span>

        <template slot="header-dropdown">
          <dropdown has-no-padding class="header__dropdown">
            <template slot="button">
              <span
                class="header__dropdown-menu pointer vertical-dots"
                data-toggle="dropdown"
              ></span>
            </template>
            <template slot="list-elements">
              <create-treatment-options should-select-diagnostic-create-treatment-type />
            </template>
          </dropdown>
        </template>
      </mobile-header>

      <create-treatment-options class="otherXS" should-select-diagnostic-create-treatment-type />
      <section class="create-treatment__recommendation recommendation">
        <treatment-directory-recommendation v-if="hasTreatmentDirectoryRecommendations" />
        <diagnostic-recommendation v-else-if="hasDiagnosticRecommendation" />
      </section>
      <main>
        <treatment-product-dropdown-modal />
        <treat :is-opened="isTreatSectionOpened" @toggle-selection="toggleTreatSectionSelection" />
        <cleanse />
        <rebalance-and-moisturize />
        <protect-treatment
          :is-opened="isProtectSectionOpened"
          @toggle-selection="toggleProtectSectionSelection"
        />
      </main>
    </div>

    <create-treatment-actions
      @send-offer="onSendOfferClick"
      @create-treatment="onCreateTreatmentClick"
    />

    <empty-serum-confirmation-modal />
    <empty-eye-contour-serum-warning-modal />
    <one-bottle-confirmation-modal />
    <promo-actives-usage-modal />
    <summary-treatment-modal />
    <send-offer-modal />
    <offer-composition-modal />
    <review-send-offer-message-modal />
    <finish-send-offer-modal class="otherXS" />
    <finish-treatment-validation-modal class="otherXS" />
    <past-treatment-created-modal class="otherXS" />
    <review-message-modal />
    <nexultra-o-recommendation-modal :patient-first-name="currentPatientFirstName" />
    <treatment-directory-modals />
    <diagnostic-not-validated-modal />
    <skin-sensitive-warning-modal />
    <inaccessible-nexultra-1-and-2-inform-modal />
    <inaccessible-nexultra3-inform-modal />
    <inaccessible-universkin-m-inform-modal />
    <inaccessible-serum-inform-modal/>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { first, get } from 'lodash';

import Treat from '@/modules/dashboard/components/create-treatment-tab/treatments/Treat';
import Cleanse from '@/modules/dashboard/components/create-treatment-tab/treatments/Cleanse';
import RebalanceAndMoisturize from '@/modules/dashboard/components/create-treatment-tab/treatments/RebalanceAndMoisturize';
import ProtectTreatment from '@/modules/dashboard/components/create-treatment-tab/treatments/ProtectTreatment';
import TreatmentProductDropdownModal from '@/modules/dashboard/components/create-treatment-tab/treatments/TreatmentProductDropdownModal';
import CreateTreatmentOptions from '@/modules/dashboard/components/create-treatment-tab/CreateTreatmentOptions';
import SummaryTreatmentModal from '@/modules/dashboard/components/create-treatment-tab/SummaryTreatmentModal';
import SendOfferModal from '@/modules/dashboard/components/create-treatment-tab/send-offer/SendOfferModal';
import OfferCompositionModal from '@/modules/dashboard/components/create-treatment-tab/send-offer/OfferCompositionModal';
import ReviewSendOfferMessageModal from '@/modules/dashboard/components/create-treatment-tab/send-offer/ReviewSendOfferMessageModal';
import FinishSendOfferModal from '@/modules/dashboard/components/create-treatment-tab/send-offer/FinishSendOfferModal';
import ReviewMessageModal from '@/modules/dashboard/components/create-treatment-tab/ReviewMessageModal';
import MobileHeader from '@/modules/dashboard/components/common/MobileHeader';
import DiagnosticRecommendation from '@/modules/dashboard/components/create-treatment-tab/DiagnosticRecommendation';
import FinishTreatmentValidationModal from '@/modules/dashboard/components/create-treatment-tab/FinishTreatmentValidationModal';
import PastTreatmentCreatedModal from '@/modules/dashboard/components/create-treatment-tab/PastTreatmentCreatedModal';
import EmptySerumConfirmationModal from '@/modules/dashboard/components/create-treatment-tab/EmptySerumConfirmationModal';
import EmptyEyeContourSerumWarningModal from '@/modules/dashboard/components/create-treatment-tab/EmptyEyeContourSerumWarningModal';
import OneBottleConfirmationModal from '@/modules/dashboard/components/create-treatment-tab/OneBottleConfirmationModal';
import PromoActivesUsageModal from '@/modules/dashboard/components/create-treatment-tab/PromoActivesUsageModal';
import TreatmentDirectoryRecommendation from '@/modules/dashboard/components/create-treatment-tab/TreatmentDirectoryRecommendation';
import TreatmentDirectoryModals from '@/modules/dashboard/components/common/TreatmentDirectoryModals';
import Dropdown from '@/modules/dashboard/components/common/Dropdown';
import DiagnosticNotValidatedModal from '@/modules/dashboard/components/create-treatment-tab/DiagnosticNotValidatedModal';
import NexultraORecommendationModal from '@/modules/dashboard/components/dashboard/NexultraORecommendationModal';
import SkinSensitiveWarningModal from '@/modules/dashboard/components/dashboard/SkinSensitiveWarningModal';
import InaccessibleNexultra1And2InformModal from '@/modules/dashboard/components/dashboard/InaccessibleNexultra1And2InformModal';
import InaccessibleNexultra3InformModal from '@/modules/dashboard/components/dashboard/InaccessibleNexultra3InformModal';
import InaccessibleUniverskinMInformModal from '@/modules/dashboard/components/dashboard/InaccessibleUniverskinMInformModal';
import InaccessibleSerumInformModal from '@/modules/dashboard/components/dashboard/InaccessibleSerumInformModal';
import CreateTreatmentActions from '@/modules/dashboard/components/create-treatment-tab/CreateTreatmentActions';

import { isMobileDevice } from '@/modules/dashboard/utils';
import { getBottleActivesNumber } from '@/modules/dashboard/utils/create-treatment-utils';
import { shouldDisplayNexultraORecommendationModal } from '@/modules/dashboard/business-logic/recommendations';
import { getTreatmentActionScores } from '@/modules/dashboard/api/scores';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

import {
  ACTIVES_PROMO_NUMBER,
  CREATE_TREATMENT_ERRORS,
  CREATE_TREATMENT_TYPES,
  TREATMENT_SECTION_NAME,
  UNSAVED_CHANGES_INPUTS,
  CREATE_TREATMENT_SECTION_NAMES,
  PRODUCT_REFERENCES,
  SERUM_USAGE_KEYS
} from '@/modules/dashboard/api/constants';
import { COUNTRY_ISO } from '@/api/places/COUNTRIES';

import {
  validateContraindications,
  validateOfflineTreatment
} from '@/modules/dashboard/business-logic/microneedling';


const PATIENT_CARD_PAGE_NAME = 'PatientCard';

const TREATMENT_TYPES_PAGES_TO_REDIRECT_ON_PATIENT_CARD = [
  'NewTreatmentPatientDetails',
  'PhotoAnalysisClarification',
  'TreatmentDirectory'
];

const TREAT_SECTION_SCROLL_OFFSET = {
  MOBILE: -55,
  DESKTOP: -165
};

const TREAT_SECTION_SERUM_SELECTORS = {
  [CREATE_TREATMENT_ERRORS.TREAT_FACE_FIRST_BOTTLE_SERUM_VALIDATION]: '.face-serum__container',
  [CREATE_TREATMENT_ERRORS.TREAT_FACE_SECOND_BOTTLE_SERUM_VALIDATION]: '.treat-face__evening-serum',
  [CREATE_TREATMENT_ERRORS.TREAT_FACE_ONE_BOTTLE_VALIDATION]: '.face-serum__container',
  [CREATE_TREATMENT_ERRORS.TREAT_EYES_BOTTLE_SERUM_VALIDATION]: '.eye-contour__serum'
};

const EMPTY_SERUM_BOTTLE_ERRORS = [
  CREATE_TREATMENT_ERRORS.TREAT_FACE_FIRST_BOTTLE_SERUM_VALIDATION,
  CREATE_TREATMENT_ERRORS.TREAT_FACE_SECOND_BOTTLE_SERUM_VALIDATION
];

const COUNTRIES_WITH_AVAILABLE_NEXULTRA_1_2_3 = [
  COUNTRY_ISO.MEXICO,
  COUNTRY_ISO.SINGAPORE,
  COUNTRY_ISO.HONG_KONG,
  COUNTRY_ISO.SAUDIA_ARABIA
];

const COUNTRIES_WITH_UNAVAILABLE_UNIVERSKIN_M = [
  COUNTRY_ISO.MEXICO,
  COUNTRY_ISO.SAUDIA_ARABIA,
  COUNTRY_ISO.SINGAPORE,
  COUNTRY_ISO.HONG_KONG
];

const COUNTRIES_WITH_UNAVAILABLE_DMAE = [
  COUNTRY_ISO.AUSTRALIA
];

export default {
  name: 'CreateTreatmentTab',
  components: {
    PromoActivesUsageModal,
    TreatmentDirectoryModals,
    TreatmentDirectoryRecommendation,
    EmptyEyeContourSerumWarningModal,
    EmptySerumConfirmationModal,
    OneBottleConfirmationModal,
    FinishTreatmentValidationModal,
    PastTreatmentCreatedModal,
    DiagnosticRecommendation,
    ProtectTreatment,
    RebalanceAndMoisturize,
    Cleanse,
    Treat,
    TreatmentProductDropdownModal,
    MobileHeader,
    Dropdown,
    SummaryTreatmentModal,
    SendOfferModal,
    OfferCompositionModal,
    ReviewSendOfferMessageModal,
    FinishSendOfferModal,
    ReviewMessageModal,
    CreateTreatmentOptions,
    DiagnosticNotValidatedModal,
    NexultraORecommendationModal,
    SkinSensitiveWarningModal,
    InaccessibleNexultra1And2InformModal,
    InaccessibleNexultra3InformModal,
    InaccessibleUniverskinMInformModal,
    InaccessibleSerumInformModal,
    CreateTreatmentActions
  },
  isMobileDevice,
  props: {
    id: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      isTreatSectionOpened: false,
      isProtectSectionOpened: false
    };
  },
  computed: {
    ...mapGetters({
      currency: dashTypes.getters.GET_CURRENCY,
      summaryTreatment: dashTypes.getters.SUMMARY_TREATMENT,
      isSummaryTreatmentSelected: dashTypes.getters.IS_SUMMARY_TREATMENT_SELECTED,
      skinCharacteristics: dashTypes.getters.SKIN_CHARACTERISTICS_BY_RECOMMENDATION_DIAGNOSTIC,
      createTreatmentType: dashTypes.getters.CREATE_TREATMENT_TYPE,
      summaryTreatmentPrice: dashTypes.getters.SUMMARY_TREATMENT_TOTAL_PRICE,
      summaryTreatmentDuration: dashTypes.getters.SUMMARY_TREATMENT_TOTAL_DURATION,
      createTreatmentErrors: dashTypes.getters.CREATE_TREATMENT_ERRORS,
      recommendationDiagnostic: dashTypes.getters.RECOMMENDATION_DIAGNOSTIC,
      hasWebshopAccess: rootTypes.getters.HAS_WEBSHOP_ACCESS,
      treatmentDirectoryRecommendations: dashTypes.getters.TREATMENT_DIRECTORY_RECOMMENDATIONS,
      treatFace: dashTypes.getters.TREAT_FACE,
      isMicroneedlingActive: dashTypes.getters.IS_MICRONEEDLING_ACTIVE,
      getIsMicroneedlingActiveForTreatmentSection:
        dashTypes.getters.GET_IS_MICRONEEDLING_ACTIVE_FOR_TREATMENT_SECTION,
      diagnostics: dashTypes.getters.CURRENT_PATIENT_DIAGNOSTICS,
      currentPatientFirstName: dashTypes.getters.CURRENT_PATIENT_FIRST_NAME,
      currentPatientId: dashTypes.getters.CURRENT_PATIENT_ID,
      treatmentRecommendation: dashTypes.getters.TREATMENT_RECOMMENDATION,
      moisturizeProducts: dashTypes.getters.MOISTURIZE_PRODUCTS,
      protectTreatment: dashTypes.getters.PROTECT_TREATMENT,
      user: rootTypes.getters.GET_USER
    }),
    hasTreatmentRecommendation() {
      return this.hasTreatmentDirectoryRecommendations || this.hasDiagnosticRecommendation;
    },
    hasTreatmentDirectoryRecommendations() {
      return (
        this.createTreatmentType === CREATE_TREATMENT_TYPES.TREATMENT_DIRECTORY &&
        this.treatmentDirectoryRecommendations
      );
    },
    hasDiagnosticRecommendation() {
      return (
        this.createTreatmentType === CREATE_TREATMENT_TYPES.DIAGNOSTIC_BASED_TREATMENT &&
        this.recommendationDiagnostic
      );
    },
    hasEmptyFaceSerumBottleErrors() {
      return this.createTreatmentErrors.some(error => EMPTY_SERUM_BOTTLE_ERRORS.includes(error));
    },
    hasOneSerumBottleErrors() {
      return this.createTreatmentErrors.some(
        error => error === CREATE_TREATMENT_ERRORS.TREAT_FACE_ONE_BOTTLE_VALIDATION
      );
    },
    hasEmptyEyeContourSerumBottleError() {
      return this.createTreatmentErrors.some(
        error => error === CREATE_TREATMENT_ERRORS.TREAT_EYES_BOTTLE_SERUM_VALIDATION
      );
    }
  },
  watch: {
    treatmentRecommendation() {
      if (!this.treatmentRecommendation) {
        return;
      }

      this.openProtectSectionWhenTreatmentDirectoryRecommendationApplied();

      const { onlyOneFaceActiveWarning } = this.treatmentRecommendation;

      if (onlyOneFaceActiveWarning) {
        this.$modal.show('skin-sensitive-warning-modal');
      }
    }
  },
  async mounted() {
    this.isTreatSectionOpened = true;
    this.setLoading(true);

    if (isMobileDevice() && this.id) {
      await this.loadPatient(this.id);
    }

    try {
      if (isMobileDevice() && this.isSummaryTreatmentSelected) {
        return;
      }

      if (this.treatmentDirectoryRecommendations) {
        await this.selectTreatmentDirectoryRecommendations();
        return;
      }

      await this.selectDiagnosticRecommendation();
    } catch (error) {
      await this.selectTreatmentFromScratchType();

      throw error;
    } finally {
      this.setLoading(false);
    }
  },
  destroyed() {
    if (!isMobileDevice()) {
      this.resetTreatmentRecommendation();
      this.resetSummaryTreatment();
    }

    this.resetMoisturizeUsages();
    this.setNotValidatedDiagnosticId(null);
  },
  async beforeRouteLeave(to, from, next) {
    if (!isMobileDevice() || !TREATMENT_TYPES_PAGES_TO_REDIRECT_ON_PATIENT_CARD.includes(to.name)) {
      next();

      return;
    }

    next({ name: PATIENT_CARD_PAGE_NAME });
  },
  methods: {
    ...mapMutations({
      resetTreatmentDirectory: dashTypes.mutations.RESET_TREATMENT_DIRECTORY,
      setNotValidatedDiagnosticId: dashTypes.mutations.SET_NOT_VALIDATED_DIAGNOSTIC_ID,
      resetMoisturizeUsages: dashTypes.mutations.RESET_MOISTURIZE_USAGES
    }),
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      loadPatient: dashTypes.actions.LOAD_PATIENT,
      resetTreatmentRecommendation: dashTypes.actions.RESET_TREATMENT_RECOMMENDATION,
      resetSummaryTreatment: dashTypes.actions.RESET_SUMMARY_TREATMENT,
      validateSummaryTreatment: dashTypes.actions.VALIDATE_SUMMARY_TREATMENT,
      updateTreatFace: dashTypes.actions.UPDATE_TREAT_FACE,
      toggleComplementaryCareProduct: dashTypes.actions.TOGGLE_COMPLEMENTARY_CARE_PRODUCT,
      updateMoistirizeProducts: dashTypes.actions.UPDATE_MOISTURIZE_PRODUCTS,
      selectCreateTreatmentType: dashTypes.actions.SELECT_CREATE_TREATMENT_TYPE
    }),
    toggleTreatSectionSelection() {
      this.isTreatSectionOpened = !this.isTreatSectionOpened;
    },
    toggleProtectSectionSelection() {
      this.isProtectSectionOpened = !this.isProtectSectionOpened;
    },
    getScrollSelectorForTreatmentError() {
      const createTreatmentError = first(this.createTreatmentErrors);

      return TREAT_SECTION_SERUM_SELECTORS[createTreatmentError];
    },
    onSerumBottleError() {
      this.isTreatSectionOpened = true;
      this.scrollToSectionOnError();
    },
    async onCreateTreatmentClick() {
      await this.validateSummaryTreatment();

      if (this.hasEmptyFaceSerumBottleErrors) {
        const isEmptyFaceSerumConfimed = await this.checkEmptyFaceSerumConfirmation();

        if (!isEmptyFaceSerumConfimed) {
          this.onSerumBottleError();

          return;
        }
      }

      const isEyeContourMicroneedlingActive = this.getIsMicroneedlingActiveForTreatmentSection(
        TREATMENT_SECTION_NAME.TREAT_EYES
      );

      if (!isEyeContourMicroneedlingActive && this.hasEmptyEyeContourSerumBottleError) {
        await this.showEmptyEyeContourSerumWarningModal();
        this.onSerumBottleError();

        return;
      }

      const {
        shouldReceiveConsent: shouldShowContradictionsModal,
        shouldRejectOnReceivingConsent: shouldRejectOnReceivingContradictionsConsent
      } = validateContraindications({
        isMicroneedling: this.isMicroneedlingActive
      });

      if (shouldShowContradictionsModal) {
        const { confirm } = await this.$asyncModal.show('contraindications-warning-modal');

        if (shouldRejectOnReceivingContradictionsConsent || !confirm) {
          return;
        }
      }

      await this.handleRecommendNexultraO();

      this.goToSummaryTreatment();
    },
    async onSendOfferClick() {
      const {
        shouldReceiveConsent: shouldShowOnlineOfferWarning,
        shouldRejectOnReceivingConsent: shouldRejectOnlineOffer
      } = validateOfflineTreatment({
        isMicroneedling: this.isMicroneedlingActive
      });

      if (shouldShowOnlineOfferWarning) {
        const { confirm } = await this.$asyncModal.show('online-offer-forbidden-warning-modal');

        if (shouldRejectOnlineOffer || !confirm) {
          return;
        }
      }

      await this.validateSummaryTreatment();

      if (this.hasEmptyFaceSerumBottleErrors) {
        const isEmptySerumConfimed = await this.checkEmptyFaceSerumConfirmation();

        if (!isEmptySerumConfimed) {
          this.onSerumBottleError();

          return;
        }
      }

      if (this.hasOneSerumBottleErrors) {
        const isOneBottleConfimed = await this.checkOneBottleConfirmation();

        if (!isOneBottleConfimed) {
          this.onSerumBottleError();

          return;
        }

        const { compositionBottle1, compositionBottle2 } = this.treatFace;

        if (compositionBottle1) {
          await this.updateTreatFace({
            ...this.treatFace,
            compositionBottle1: { ...compositionBottle1, isDuplicated: true }
          });
        } else if (compositionBottle2) {
          await this.updateTreatFace({
            ...this.treatFace,
            compositionBottle2: { ...compositionBottle2, isDuplicated: true }
          });
        }
      }

      if (this.hasEmptyEyeContourSerumBottleError) {
        await this.showEmptyEyeContourSerumWarningModal();
        this.onSerumBottleError();

        return;
      }

      const activesTotalNumber =
        getBottleActivesNumber(this.treatFace.compositionBottle1) +
        getBottleActivesNumber(this.treatFace.compositionBottle2);

      if (activesTotalNumber > ACTIVES_PROMO_NUMBER) {
        const isPromoActivesUsageConfirmed = await this.checkPromoActivesUsageConfirmation();

        if (!isPromoActivesUsageConfirmed) {
          return;
        }
      }

      const { country } = this.user;

      if (!COUNTRIES_WITH_AVAILABLE_NEXULTRA_1_2_3.includes(country)) {
        await this.handleUnavailableNexultra123();
      }

      if (COUNTRIES_WITH_UNAVAILABLE_UNIVERSKIN_M.includes(country)) {
        const shouldPreventSendOffer = await this.handleRecommendUniverskinM();

        if (!shouldPreventSendOffer) {
          return;
        }
      }

      if (COUNTRIES_WITH_UNAVAILABLE_DMAE.includes(country)) {
        const shouldPreventSendOffer = await this.handleBlockDmaeAustralia();
        if (shouldPreventSendOffer) {
          return;
        }
      }

      await this.handleRecommendNexultraO();

      this.goToSendOffer();
    },
    async handleBlockDmaeAustralia() {
      const treatEyesCompositionBottle1 = get(
        this.summaryTreatment,
        'treatEyes.compositionBottle1',
        []
      );

      const treatFaceCompositionBottle1 = get(
        this.summaryTreatment,
        'treatFace.compositionBottle1',
        []
      );

      const treatFaceCompositionBottle2 = get(
        this.summaryTreatment,
        'treatFace.compositionBottle2',
        []
      );
      let treatEyesContainsDMA = false;
      let treatFace1ContainsDMA = false;
      let treatFace2ContainsDMA = false;
      for (let i = 1; i <= 3; i += 1) {
        const cbActif = get(treatEyesCompositionBottle1, `actif${i}`, []);
        if ((cbActif != null) && (cbActif.name === 'dmae')) {
          treatEyesContainsDMA = true;
          break;
        }
      }
      if (!treatEyesContainsDMA) {
        for (let i = 1; i <= 3; i += 1) {
          const cbActif = get(treatFaceCompositionBottle1, `actif${i}`, []);
          if ((cbActif != null) && (cbActif.name === 'dmae')) {
            treatFace1ContainsDMA = true;
            break;
          }
        }
        for (let i = 1; i <= 3; i += 1) {
          if (treatFace1ContainsDMA) break;
          const cbActif = get(treatFaceCompositionBottle2, `actif${i}`, []);
          if ((cbActif != null) && (cbActif.name === 'dmae')) {
            treatFace2ContainsDMA = true;
            break;
          }
        }
      }
      const summaryTreatmentIncludesDmae = treatEyesContainsDMA || treatFace1ContainsDMA || treatFace2ContainsDMA;
      if (summaryTreatmentIncludesDmae) {
        const { confirm } = await this.$asyncModal.show('inaccessible-serum-inform-modal');
        return confirm;
      }
    },
    async handleRecommendNexultraO() {
      const actionScores = getTreatmentActionScores(
        this.summaryTreatment.treatFace,
        this.skinCharacteristics
      );

      const summaryTreatmentIncludesNexultraO = get(
        this.summaryTreatment,
        [CREATE_TREATMENT_SECTION_NAMES.CLEANSE],
        []
      ).includes(PRODUCT_REFERENCES.NEXULTRA_O);

      const shouldRecommendNexultraO = shouldDisplayNexultraORecommendationModal(
        this.hasDiagnosticRecommendation,
        summaryTreatmentIncludesNexultraO,
        actionScores
      );

      if (shouldRecommendNexultraO) {
        const { confirm } = await this.$asyncModal.show('nexultra-o-recommandation-modal');

        if (confirm) {
          this.toggleComplementaryCareProduct({
            product: { name: PRODUCT_REFERENCES.NEXULTRA_O },
            section: CREATE_TREATMENT_SECTION_NAMES.CLEANSE,
            isActive: true
          });
        }
      }
    },
    async handleRecommendUniverskinM() {
      const summaryTreatmentIncludesUniverskinM = get(
        this.summaryTreatment,
        [CREATE_TREATMENT_SECTION_NAMES.PROTECT],
        []
      ).includes(PRODUCT_REFERENCES.UNIVERSKIN_M);

      if (summaryTreatmentIncludesUniverskinM) {
        const { confirm } = await this.$asyncModal.show('inaccessible-universkin-m-inform-modal');

        if (confirm) {
          this.toggleComplementaryCareProduct({
            product: { name: PRODUCT_REFERENCES.UNIVERSKIN_M },
            section: CREATE_TREATMENT_SECTION_NAMES.PROTECT,
            isActive: false
          });
        }

        return !!this.summaryTreatmentPrice;
      }

      return true;
    },
    async handleUnavailableNexultra123() {
      const { moisturize } = this.summaryTreatment;

      const shouldOfferReplaceProducts = this.moisturizeProducts.some(
        ({ name }) =>
          name === PRODUCT_REFERENCES.NEXULTRA_P_LIGHT ||
          name === PRODUCT_REFERENCES.NEXULTRA_P_RICH
      );

      const nexultra1 = moisturize.find(product => product === PRODUCT_REFERENCES.NEXULTRA_1);
      const nexultra2 = moisturize.find(product => product === PRODUCT_REFERENCES.NEXULTRA_2);
      const nexultra3 = moisturize.find(product => product === PRODUCT_REFERENCES.NEXULTRA_3);

      if (nexultra1 || nexultra2) {
        const selectedProducts = [nexultra1, nexultra2].filter(p => !!p);
        const { confirm } = await this.$asyncModal.show('inaccessible-nexultra-1-2-inform-modal', {
          shouldOfferReplaceProducts,
          selectedProducts
        });

        if (confirm) {
          await this.onReplaceMoisturizeProduct(
            [nexultra1, nexultra2],
            PRODUCT_REFERENCES.NEXULTRA_P_LIGHT
          );
        }
      }

      if (nexultra3) {
        const nexUltra3ModalParams = { shouldOfferReplaceProducts };
        const { confirm: confirmNexUltra3 } = await this.$asyncModal.show(
          'inaccessible-nexultra3-inform-modal',
          nexUltra3ModalParams
        );

        if (confirmNexUltra3) {
          await this.onReplaceMoisturizeProduct([nexultra3], PRODUCT_REFERENCES.NEXULTRA_P_RICH);
        }
      }
    },
    async checkEmptyFaceSerumConfirmation() {
      const answer = await this.getEmptySerumConfirmationAnswer();

      return answer === UNSAVED_CHANGES_INPUTS.YES;
    },
    async checkOneBottleConfirmation() {
      const answer = await this.getOneBottleConfirmationAnswer();

      return answer === UNSAVED_CHANGES_INPUTS.YES;
    },
    async checkPromoActivesUsageConfirmation() {
      const answer = await this.getPromoActivesUsageConfirmationAnswer();

      return answer === UNSAVED_CHANGES_INPUTS.YES;
    },
    async showEmptyEyeContourSerumWarningModal() {
      return new Promise(resolve => {
        this.$modal.show('empty-eye-contour-serum-warning-modal', { answerResolver: resolve });
      });
    },
    scrollToSectionOnError() {
      const selector = this.getScrollSelectorForTreatmentError();

      const offset = isMobileDevice()
        ? TREAT_SECTION_SCROLL_OFFSET.MOBILE
        : TREAT_SECTION_SCROLL_OFFSET.DESKTOP;

      this.$scrollTo(selector, { offset });
    },
    goToSummaryTreatment() {
      if (isMobileDevice()) {
        this.$router.push({
          name: 'SummaryTreatmentComposition'
        });

        return;
      }

      this.$modal.show('summary-treatment-modal');
    },
    goToSendOffer() {
      if (isMobileDevice()) {
        this.$router.push({ name: 'SendOffer' });

        return;
      }

      this.$modal.show('send-offer-modal');
    },
    getEmptySerumConfirmationAnswer() {
      return new Promise(resolve => {
        this.$modal.show('empty-serum-confirmation-modal', { answerResolver: resolve });
      });
    },
    getOneBottleConfirmationAnswer() {
      return new Promise(resolve => {
        this.$modal.show('one-bottle-confirmation-modal', { answerResolver: resolve });
      });
    },
    getPromoActivesUsageConfirmationAnswer() {
      return new Promise(resolve => {
        this.$modal.show('promo-actives-usage-modal', { answerResolver: resolve });
      });
    },
    async onReplaceMoisturizeProduct(productNames, productNameForReplace) {
      const selectedProducts = this.moisturizeProducts.filter(({ name }) =>
        productNames.includes(name)
      );

      const productForReplace = this.moisturizeProducts.find(
        ({ name }) => name === productNameForReplace
      );
      const [firstProduct, secondProduct] = selectedProducts;

      const replacementProductUsage =
        firstProduct && secondProduct ? SERUM_USAGE_KEYS.MORNING_AND_EVENING : firstProduct.usage;

      const replacementProduct = { ...productForReplace, usage: replacementProductUsage };

      selectedProducts.forEach(async product => {
        await this.updateMoistirizeProducts({
          product,
          isActive: false,
          isUpdate: false,
          modal: this.$modal
        });
      });

      await this.updateMoistirizeProducts({
        product: replacementProduct,
        isActive: true,
        isUpdate: false,
        modal: this.$modal
      });
    },
    async selectTreatmentFromScratchType() {
      await this.selectCreateTreatmentType({
        createTreatmentType: CREATE_TREATMENT_TYPES.TREATMENT_FROM_SCRATCH
      });
    },
    async selectDiagnosticRecommendation() {
      if (this.recommendationDiagnostic) {
        await this.selectCreateTreatmentType({
          createTreatmentType: CREATE_TREATMENT_TYPES.DIAGNOSTIC_BASED_TREATMENT,
          recommendationDiagnostic: this.recommendationDiagnostic
        });

        return;
      }

      const firstDiagnostic = first(this.diagnostics);
      const { selectDefaultOption = true } = this.$route.query;

      if (!firstDiagnostic || !selectDefaultOption) {
        await this.selectTreatmentFromScratchType();
        return;
      }

      await this.selectCreateTreatmentType({
        createTreatmentType: CREATE_TREATMENT_TYPES.DIAGNOSTIC_BASED_TREATMENT,
        recommendationDiagnostic: firstDiagnostic
      });
    },
    openProtectSectionWhenTreatmentDirectoryRecommendationApplied() {
      const hasTreatmentDirectoryRecommendationsApplied =
        this.treatmentRecommendation &&
        this.treatmentDirectoryRecommendations &&
        this.treatmentDirectoryRecommendations.name;

      const nexUltraZSelected = this.protectTreatment.includes(PRODUCT_REFERENCES.SUNSCREEN_SPF);

      if (hasTreatmentDirectoryRecommendationsApplied && nexUltraZSelected) {
        this.isProtectSectionOpened = true;
      }
    },
    async selectTreatmentDirectoryRecommendations() {
      await this.selectCreateTreatmentType({
        createTreatmentType: CREATE_TREATMENT_TYPES.TREATMENT_DIRECTORY,
        treatmentDirectoryRecommendations: this.treatmentDirectoryRecommendations
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/text';
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/buttons';

.create-treatment {
  background-color: $white;
  padding: 25px 40px 32px;
  border-radius: 8px;

  &__container {
    position: relative;
    margin-bottom: 50px;
  }

  &__type-buttons {
    margin-bottom: 20px;
  }

  &__recommendation {
    min-height: 10px;
    margin-top: 20px;
    margin-bottom: 15px;
  }
}

.header__dropdown-menu {
  display: block;
}

.header__dropdown {
  .dropdown-menu {
    min-width: 300px;
  }
}

.recommendation {
  &__none {
    display: none;
  }
}

.header-title {
  flex-grow: 1;
}

@media (max-width: 930px) {
  .create-treatment {
    padding: 28px 15px 32px;
  }
}

@media (max-width: 767px) {
  .create-treatment {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border-radius: 0;
    margin: 0;
    padding: 0;

    &__container {
      min-height: 100vh;

      margin-bottom: 0;
    }

    &__type-buttons {
      margin-bottom: 20px;
    }

    &__recommendation {
      display: flex;
      justify-content: center;

      padding: 0 15px;
      margin-bottom: 15px;
    }
  }

  .recommendation {
    &__none {
      display: none;
    }
  }
}
</style>
